<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <p v-if="editMode" class="title">Edit Digital Media</p>
        <p v-else class="title">Add Digital Media</p>
      </div>
    </div>
    <hr />
    <form @submit.prevent="submitForm" id="editorForm">
      <div class="columns">
        <div class="column">
          <div class="box">
            <div class="columns is-vcentered">
              <div class="column"></div>
              <div class="column is-narrow pt-1">
                <p class="subtitle">
                  <i class="mdi mdi-human-male-child pr-1 has-text-danger"></i>
                  Kids Content
                </p>
              </div>
              <div class="column is-narrow">
                <b-switch
                  v-model="formData.kidsContent"
                  type="is-success"
                ></b-switch>
              </div>
              <div class="column is-narrow pt-1">
                <p class="subtitle">
                  <i class="mdi mdi-crown pr-1 has-text-warning"></i>
                  Premium Content
                </p>
              </div>
              <div class="column is-narrow">
                <b-switch
                  v-model="formData.premiumContent"
                  type="is-success"
                ></b-switch>
              </div>
            </div>
          </div>
          <div class="box">
            <div class="columns is-multiline">
              <div class="column is-6">
                <b-field label="Type*">
                  <b-select
                    required
                    v-model="formData.type"
                    placeholder="Select a type"
                    disabled
                  >
                    <option value="image">Image</option>
                    <option value="document">Document</option>
                    <option value="audio">Audio</option>
                    <option value="video">Video</option>
                    <option value="dataset">Dataset</option>
                    <option value="map">Map</option>
                  </b-select>
                </b-field>
              </div>
              <div v-if="showFormData.title" class="column is-6">
                <b-field :label="requiredFormData.title ? 'Title*' : 'Title'">
                  <b-input
                    v-model="formData.title"
                    :required="requiredFormData.title"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.subTitle" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.subTitle ? 'Sub-Title*' : 'Sub-Title'
                  "
                >
                  <b-input
                    v-model="formData.subTitle"
                    :required="requiredFormData.subTitle"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.description" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.description
                      ? 'Description*'
                      : 'Description'
                  "
                >
                  <b-input
                    v-model="formData.description"
                    :required="requiredFormData.description"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.subject" class="column is-6">
                <b-field
                  :label="requiredFormData.subject ? 'Subject*' : 'Subject'"
                >
                  <b-input
                    v-model="formData.subject"
                    :required="requiredFormData.subject"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.keywords" class="column is-6">
                <b-field
                  :label="requiredFormData.keywords ? 'Keywords*' : 'Keywords'"
                  message="Enter multiple values separated by comma (,)"
                >
                  <b-input
                    v-model="formData.keywords"
                    :required="requiredFormData.keywords"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.publicationYear" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.publicationYear
                      ? 'Publication Year*'
                      : 'Publication Year'
                  "
                  message="Enter year in the format YYYY e.g. 2021"
                >
                  <b-numberinput
                    v-model="formData.publicationYear"
                    :required="requiredFormData.publicationYear"
                    :min="1000"
                    :max="9999"
                    :controls="false"
                    expanded
                  ></b-numberinput>
                </b-field>
              </div>
              <div v-if="showFormData.ISSN" class="column is-6">
                <b-field :label="requiredFormData.ISSN ? 'ISSN*' : 'ISSN'">
                  <b-input
                    v-model="formData.ISSN"
                    :required="requiredFormData.ISSN"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.journalName" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.journalName
                      ? 'Journal Name*'
                      : 'Journal Name'
                  "
                >
                  <b-input
                    v-model="formData.journalName"
                    :required="requiredFormData.journalName"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.newspaperSource" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.newspaperSource
                      ? 'Newspaper Source*'
                      : 'Newspaper Source'
                  "
                >
                  <b-input
                    v-model="formData.newspaperSource"
                    :required="requiredFormData.newspaperSource"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.publicationDate" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.publicationDate
                      ? 'Publication Date*'
                      : 'Publication Date'
                  "
                >
                  <b-datepicker
                    v-model="formData.publicationDate"
                    :required="requiredFormData.publicationDate"
                    expanded
                  ></b-datepicker>
                </b-field>
              </div>
              <div v-if="showFormData.duration" class="column is-6">
                <b-field
                  :label="requiredFormData.duration ? 'Duration*' : 'Duration'"
                >
                  <b-input
                    v-model="formData.duration"
                    :required="requiredFormData.duration"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.accessionNo" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.accessionNo
                      ? 'Accession Number*'
                      : 'Accession Number'
                  "
                >
                  <b-input
                    v-model="formData.accessionNo"
                    :required="requiredFormData.accessionNo"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.callNo" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.callNo ? 'Call Number*' : 'Call Number'
                  "
                >
                  <b-input
                    v-model="formData.callNo"
                    :required="requiredFormData.callNo"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.volume" class="column is-6">
                <b-field
                  :label="requiredFormData.volume ? 'Volume*' : 'Volume'"
                >
                  <b-input
                    v-model="formData.volume"
                    :required="requiredFormData.volume"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.pages" class="column is-6">
                <b-field :label="requiredFormData.pages ? 'Pages*' : 'Pages'">
                  <b-input
                    v-model="formData.pages"
                    :required="requiredFormData.pages"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.notes" class="column is-6">
                <b-field :label="requiredFormData.notes ? 'Notes*' : 'Notes'">
                  <b-input
                    v-model="formData.notes"
                    :required="requiredFormData.notes"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.bookReview" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.bookReview ? 'Book Review*' : 'Book Review'
                  "
                >
                  <b-input
                    v-model="formData.bookReview"
                    :required="requiredFormData.bookReview"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.authors" class="column is-6">
                <b-field
                  :label="
                    formData.type == 'video' || formData.type == 'audio'
                      ? 'Director/Producer*'
                      : formData.type == 'image' || formData.type == 'map'
                      ? 'Contributor Names*'
                      : 'Authors*'
                  "
                  message="Enter multiple values separated by comma (,)"
                >
                  <b-input
                    v-model="formData.authors"
                    :required="requiredFormData.authors"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.publisher" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.publisher ? 'Publisher*' : 'Publisher'
                  "
                >
                  <b-input
                    v-model="formData.publisher"
                    :required="requiredFormData.publisher"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.placeOfPublication" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.placeOfPublication
                      ? 'Place of Publication*'
                      : 'Place of Publication'
                  "
                >
                  <b-input
                    v-model="formData.placeOfPublication"
                    :required="requiredFormData.placeOfPublication"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.language" class="column is-6">
                <b-field
                  :label="requiredFormData.language ? 'Language*' : 'Language'"
                >
                  <b-input
                    v-model="formData.language"
                    :required="requiredFormData.language"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div
                v-if="showFormData.weblink && formData.type != 'video'"
                class="column is-6"
              >
                <b-field
                  :label="formData.type === 'video' ? 'Video URL' : 'URL Link'"
                >
                  <b-input
                    v-model="formData.weblink"
                    :required="requiredFormData.weblink"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="formData.type == 'video'" class="column is-6">
                <b-field
                  :label="formData.type === 'video' ? 'Video URL' : 'URL Link'"
                >
                  <b-input
                    v-model="formData.attachmentURL"
                    :required="requiredFormData.weblink"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.contributorNames" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.contributorNames
                      ? 'Contributor Names*'
                      : 'Contributor Names'
                  "
                  message="Enter multiple values separated by comma (,)"
                >
                  <b-input
                    v-model="formData.contributorNames"
                    :required="requiredFormData.contributorNames"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.medium" class="column is-6">
                <b-field
                  :label="requiredFormData.medium ? 'Medium*' : 'Medium'"
                >
                  <b-input
                    v-model="formData.medium"
                    :required="requiredFormData.medium"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.dataType" class="column is-6">
                <b-field label="Data Type">
                  <b-input v-model="formData.dataType" expanded></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.dataSource" class="column is-6">
                <b-field label="Data Source">
                  <b-input v-model="formData.dataSource" expanded></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.category" class="column is-6">
                <b-field label="Category">
                  <b-input v-model="formData.category" expanded></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.license" class="column is-6">
                <b-field label="License">
                  <b-input v-model="formData.license" expanded></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.format" class="column is-6">
                <b-field label="Format">
                  <b-input v-model="formData.format" expanded></b-input>
                </b-field>
              </div>
              <div v-if="false && showFormData.XXXXXXXX" class="column is-6">
                <b-field label="XXXXXXXX">
                  <b-input v-model="formData.XXXXXXXX" expanded></b-input>
                </b-field>
              </div>
            </div>
            <hr />
            <div class="has-text-right">
              <b-button @click="cancel" class="button">Cancel</b-button>
              <button
                form="editorForm"
                class="button is-primary ml-4"
                type="submit"
                :disabled="isSubmitting"
              >
                {{ isSubmitting ? "Saving" : "Save" }}
              </button>
            </div>
          </div>
        </div>
        <div class="column is-4 is-narrow">
          <div v-if="formData.attachmentURL" class="box">
            <p class="pb-3 bold has-text-centered">
              <i class="mdi mdi-file-outline pr-1"></i>
              Current Uploads
            </p>
            <hr class="my-1" />
            <div v-if="formData.attachmentURL" class="mt-2">
              <div class="bold">Attachment</div>
              <div class="columns mt-1">
                <div class="column is-narrow pt-1">
                  <div v-if="$route.params.itemDetail.type == 'image'">
                    <img :src="formData.attachmentURL" style="height: 100px" />
                  </div>
                  <div v-else-if="$route.params.itemDetail.type == 'video'">
                    <a :href="formData.attachmentURL" target="_blank">
                      <b-button type="is-danger" icon-left="play">
                        Play Video
                      </b-button>
                    </a>
                  </div>
                  <div
                    v-else-if="
                      ($route.params.itemDetail.type != 'image' ||
                        $route.params.itemDetail.type != 'video') &&
                        formData.attachmentURL
                    "
                  >
                    <a :href="formData.attachmentURL">
                      <b-button type="is-info" icon-left="download">
                        Download Resource
                      </b-button>
                    </a>
                  </div>
                  <div v-else class="my-5">
                    <i class="mdi mdi-information-outline pr-1"></i> No File
                    uploaded
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box">
            <b-field label="Attach File">
              <section>
                <p
                  v-if="$route.params.itemDetail.type == 'video'"
                  class="bold pb-4 has-text-info"
                >
                  <i class="mdi mdi-information-outline pr-1"></i> Note: If
                  video file is attached then YoutubeURL <br />
                  is discarded.
                </p>
                <p
                  v-if="contentType == 'video'"
                  class="bold pb-4 has-text-info"
                >
                  <i class="mdi mdi-information-outline pr-1"></i> Note: Only
                  mp4 format supports online playback
                </p>
                <b-field>
                  <b-upload v-model="attachment" multiple drag-drop>
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"> </b-icon>
                        </p>
                        <p>Drop your files here or click to upload</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>

                <div class="tags">
                  <span
                    v-for="(file, index) in attachment"
                    :key="index"
                    class="tag is-primary"
                  >
                    {{ file.name }}
                    <button
                      class="delete is-small"
                      type="button"
                      @click="deleteDropFile(index)"
                    ></button>
                  </span>
                </div>
              </section>
            </b-field>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  name: "Editor",
  data() {
    return {
      isSubmitting: false,
      editMode: false,
      formData: {
        kidsContent: false,
        premiumContent: false,
        title: undefined,
        type: undefined,
        attachmentURL: undefined,
        description: undefined,
        contributorNames: undefined,
        publishedDate: undefined,
        subject: undefined,
        keywords: undefined,
        genre: undefined,
        notes: undefined,
        medium: undefined,
        callNumber: undefined,
        source: undefined,
        rightsAdvisory: undefined,
        accessAdvisory: undefined,
        onlineFormat: undefined,
        attachmentFile: undefined
      },
      showFormData: {
        title: false,
        type: false,
        attachmentURL: false,
        description: false,
        contributorNames: false,
        publishedDate: false,
        subject: false,
        keywords: false,
        genre: false,
        notes: false,
        medium: false,
        callNumber: false,
        source: false,
        rightsAdvisory: false,
        accessAdvisory: false,
        onlineFormat: false,
        attachmentFile: false
      },
      requiredFormData: {
        title: false,
        type: false,
        attachmentURL: false,
        description: false,
        contributorNames: false,
        publishedDate: false,
        subject: false,
        keywords: false,
        genre: false,
        notes: false,
        medium: false,
        callNumber: false,
        source: false,
        rightsAdvisory: false,
        accessAdvisory: false,
        onlineFormat: false,
        attachmentFile: false
      },
      attachment: []
    };
  },
  async created() {
    if (this.$route.params.itemDetail) {
      this.editMode = true;
      this.showFields(this.$route.params.itemDetail.type);
      for (var key in this.$route.params.itemDetail) {
        if (this.$route.params.itemDetail[key]) {
          this.formData[key] = Array.isArray(this.$route.params.itemDetail[key])
            ? this.$route.params.itemDetail[key].join(", ")
            : this.$route.params.itemDetail[key];
        }
      }
    }
  },
  computed: {},
  methods: {
    deleteDropFile(index) {
      this.attachment.splice(index, 1);
    },
    cancel() {
      this.$router.push({ name: "DigitalMedia" });
    },
    showFields(type) {
      let fields = [];
      let requiredFields = [];
      if (type == "image") {
        fields = [
          "title",
          "description",
          "authors",
          "subject",
          "keywords",
          "notes",
          "publicationYear",
          "medium",
          "callNo"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear",
          "medium"
        ];
      } else if (type == "video") {
        fields = [
          "title",
          "description",
          "authors",
          "subject",
          "keywords",
          "weblink",
          "notes",
          "publicationYear",
          "duration"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear",
          "weblink"
        ];
      } else if (type == "audio") {
        fields = [
          "title",
          "description",
          "authors",
          "subject",
          "keywords",
          "notes",
          "publicationYear",
          "duration"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear"
        ];
      } else if (type == "map") {
        fields = [
          "title",
          "description",
          "authors",
          "subject",
          "keywords",
          "publisher",
          "placeOfPublication",
          "notes",
          "publicationYear"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear",
          "publisher",
          "placeOfPublication"
        ];
      } else if (type == "dataset") {
        fields = [
          "title",
          "description",
          "authors",
          "subject",
          "keywords",
          "publisher",
          "dataType",
          "dataSource",
          "category",
          "license",
          "format",
          "placeOfPublication",
          "notes",
          "publicationYear"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear"
        ];
      }
      fields.forEach(field => {
        this.showFormData[field] = true;
      });
      requiredFields.forEach(field => {
        this.requiredFormData[field] = true;
      });
    },
    // async deleteResource() {
    //   const deleteRequest = {
    //     itemId: this.$route.params.itemDetail._id,
    //     deltype: 1
    //   };
    //   await this.$store
    //     .dispatch("deleteDigitalMedia", deleteRequest)
    //     .then(() => {
    //       this.formData.attachmentFile = undefined;
    //     });
    // },

    async submitForm() {
      this.isSubmitting = true;

      // handling array for formData
      let keywords = this.formData["keywords"];
      let authors = this.formData["authors"];
      if (keywords) {
        keywords = keywords.split(",").map(function(item) {
          return item.trim();
        });
      }
      if (authors) {
        authors = authors.split(",").map(function(item) {
          return item.trim();
        });
      }

      // cloning rest of the variables
      const request = cloneDeep(this.formData);
      // deleting array variables to avoid duplicating in form data request
      delete request["keywords"];
      delete request["authors"];

      if (this.editMode) {
        const uploadRequest = new FormData();

        if (this.formData.type !== "video") {
          uploadRequest.append("attachmentFile", this.attachment[0]);
          this.formData.attachmentURL = undefined;
        }

        for (var key1 in this.formData) {
          if (key1 != "attachmentFile" && request[key1] != undefined)
            uploadRequest.append(key1, request[key1]);
        }

        for (let i = 0; i < keywords.length; i++) {
          uploadRequest.append("keywords", keywords[i]);
        }
        for (let i = 0; i < authors.length; i++) {
          uploadRequest.append("authors", authors[i]);
        }

        if (
          await this.$store.dispatch("editDigitalMedia", {
            id: this.$route.params.itemDetail._id,
            request: uploadRequest
          })
        ) {
          this.$router.push({ name: "DigitalMedia" });
        }
      } else {
        const uploadRequest = new FormData();
        if (this.formData.type !== "video") {
          uploadRequest.append("attachmentFile", this.attachment[0]);
          this.formData.attachmentURL = undefined;
        }
        for (var key2 in this.formData) {
          if (key2 != "attachmentFile" && request[key2] != undefined)
            uploadRequest.append(key2, request[key2]);
        }

        if (await this.$store.dispatch("addDigitalMedia", uploadRequest))
          this.$router.push({ name: "DigitalMedia" });
      }
      this.isSubmitting = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
