<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <p class="title">Add Content</p>
      </div>
    </div>
    <hr />
    <form @submit.prevent="submitForm" id="editorForm">
      <div class="columns">
        <div class="column">
          <div class="box">
            <div class="columns is-vcentered">
              <div class="column is-narrow">
                <b-field label="Type*"> </b-field>
              </div>
              <div class="column">
                <b-select v-model="contentType" placeholder="Select a type">
                  <option value="PrintedBook">Book</option>
                  <option value="eBook">E-Book</option>
                  <option value="Research">Research</option>
                  <option value="eResearch">E-Research Article</option>
                  <option value="Journal">Journal</option>
                  <option value="eJournal">E-Journal</option>
                  <option value="eNewspaper">E-Newspaper</option>
                  <option value="image">Image</option>
                  <option value="video">Video</option>
                  <option value="audio">Audio</option>
                  <option value="map">Map</option>
                  <option value="dataset">Dataset</option>
                </b-select>
              </div>
              <div class="column is-narrow pt-1">
                <p class="subtitle">
                  <i class="mdi mdi-human-male-child pr-1 has-text-danger"></i>
                  Kids Content
                </p>
              </div>
              <div class="column is-narrow">
                <b-switch
                  v-model="formData.kidsContent"
                  type="is-success"
                ></b-switch>
              </div>
              <div class="column is-narrow pt-1">
                <p class="subtitle">
                  <i class="mdi mdi-crown pr-1 has-text-warning"></i>
                  Premium Content
                </p>
              </div>
              <div class="column is-narrow">
                <b-switch
                  v-model="formData.premiumContent"
                  type="is-success"
                ></b-switch>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div v-if="showFormData.accessionNo || showFormData.ISBN" class="box">
            <div v-if="showFormData.ISBN" class="mb-2">
              <b-field :label="requiredFormData.ISBN ? 'ISBN*' : 'ISBN'">
                <b-input
                  v-model="formData.ISBN"
                  expanded
                  :required="requiredFormData.ISBN"
                ></b-input>
                <p v-if="contentType == 'eBook'" class="control">
                  <b-button
                    @click="checkISBN"
                    type="is-success"
                    label="Check ISBN"
                  />
                </p>
                <p class="control">
                  <b-button
                    @click="getDetailFromISBN"
                    type="is-info"
                    label="Fetch Details"
                  />
                </p>
              </b-field>
            </div>
            <div v-if="showFormData.accessionNo">
              <b-field
                :label="
                  requiredFormData.accessionNo
                    ? 'Accession Number*'
                    : 'Accession Number'
                "
              >
                <b-input
                  v-model="formData.accessionNo"
                  :required="requiredFormData.accessionNo"
                  expanded
                ></b-input>
                <p v-if="contentType == 'PrintedBook'" class="control">
                  <b-button
                    @click="checkAcessionNo"
                    type="is-success"
                    label="Check Accession No."
                  />
                </p>
              </b-field>
            </div>
          </div>
          <div class="box">
            <div class="columns is-multiline">
              <div v-if="showFormData.title" class="column is-6">
                <b-field :label="requiredFormData.title ? 'Title*' : 'Title'">
                  <b-input
                    v-model="formData.title"
                    :required="requiredFormData.title"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.subTitle" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.subTitle ? 'Sub-Title*' : 'Sub-Title'
                  "
                >
                  <b-input
                    v-model="formData.subTitle"
                    :required="requiredFormData.subTitle"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.description" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.description
                      ? 'Description*'
                      : 'Description'
                  "
                >
                  <b-input
                    v-model="formData.description"
                    :required="requiredFormData.description"
                    expanded
                    type="textarea"
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.subject" class="column is-6">
                <b-field
                  :label="requiredFormData.subject ? 'Subject*' : 'Subject'"
                >
                  <b-input
                    v-model="formData.subject"
                    :required="requiredFormData.subject"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.keywords" class="column is-6">
                <b-field
                  :label="requiredFormData.keywords ? 'Keywords*' : 'Keywords'"
                >
                  <b-autocomplete
                    v-model="autoCompleteQuery.keywords"
                    :data="filteredKeywordsSuggestions"
                    @select="option => addKeyword(option)"
                    @keyup.native.enter="addKeyword"
                    expanded
                  >
                    <template #empty> </template>
                  </b-autocomplete>
                  <span v-for="(item, index) in formData.keywords" :key="index">
                    <b-tag
                      rounded
                      type="is-info"
                      class="mr-2 mt-2"
                      closable
                      aria-close-label="Close tag"
                      @close="
                        formData.keywords = formData.keywords.filter(
                          e => e !== item
                        )
                      "
                      >{{ item }}</b-tag
                    >
                  </span>
                </b-field>
              </div>
              <div v-if="showFormData.publicationYear" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.publicationYear
                      ? 'Publication Year*'
                      : 'Publication Year'
                  "
                  message="Enter year in the format YYYY e.g. 2021"
                >
                  <b-numberinput
                    v-model="formData.publicationYear"
                    :required="requiredFormData.publicationYear"
                    :min="1000"
                    :max="9999"
                    :controls="false"
                    expanded
                  ></b-numberinput>
                </b-field>
              </div>
              <div v-if="showFormData.ISSN" class="column is-6">
                <b-field :label="requiredFormData.ISSN ? 'ISSN*' : 'ISSN'">
                  <b-input
                    v-model="formData.ISSN"
                    :required="requiredFormData.ISSN"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.journalName" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.journalName
                      ? 'Journal Name*'
                      : 'Journal Name'
                  "
                >
                  <b-input
                    v-model="formData.journalName"
                    :required="requiredFormData.journalName"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.newspaperSource" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.newspaperSource
                      ? 'Newspaper Source*'
                      : 'Newspaper Source'
                  "
                >
                  <b-input
                    v-model="formData.newspaperSource"
                    :required="requiredFormData.newspaperSource"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.publicationDate" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.publicationDate
                      ? 'Publication Date*'
                      : 'Publication Date'
                  "
                >
                  <b-datepicker
                    v-model="formData.publicationDate"
                    :required="requiredFormData.publicationDate"
                    expanded
                  ></b-datepicker>
                </b-field>
              </div>
              <div v-if="showFormData.duration" class="column is-6">
                <b-field
                  :label="requiredFormData.duration ? 'Duration*' : 'Duration'"
                >
                  <b-input
                    v-model="formData.duration"
                    :required="requiredFormData.duration"
                    expanded
                  ></b-input>
                </b-field>
              </div>

              <div v-if="showFormData.callNo" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.callNo ? 'Call Number*' : 'Call Number'
                  "
                >
                  <b-input
                    v-model="formData.callNo"
                    :required="requiredFormData.callNo"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.volume" class="column is-6">
                <b-field
                  :label="requiredFormData.volume ? 'Volume*' : 'Volume'"
                >
                  <b-input
                    v-model="formData.volume"
                    :required="requiredFormData.volume"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.pages" class="column is-6">
                <b-field :label="requiredFormData.pages ? 'Pages*' : 'Pages'">
                  <b-input
                    v-model="formData.pages"
                    :required="requiredFormData.pages"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.notes" class="column is-6">
                <b-field :label="requiredFormData.notes ? 'Notes*' : 'Notes'">
                  <b-input
                    v-model="formData.notes"
                    :required="requiredFormData.notes"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.bookReview" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.bookReview ? 'Book Review*' : 'Book Review'
                  "
                >
                  <b-input
                    v-model="formData.bookReview"
                    :required="requiredFormData.bookReview"
                    expanded
                    type="textarea"
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.authors" class="column is-6">
                <b-field
                  :label="
                    contentType == 'video' || contentType == 'audio'
                      ? 'Director/Producer*'
                      : contentType == 'image' || contentType == 'map'
                      ? 'Contributor Names*'
                      : 'Authors*'
                  "
                >
                  <b-autocomplete
                    v-model="autoCompleteQuery.authors"
                    :data="filteredAuthorsSuggestions"
                    @select="option => addAuthor(option)"
                    @keyup.native.enter="addAuthor"
                    expanded
                  >
                    <template #empty> </template>
                  </b-autocomplete>
                  <span v-for="(item, index) in formData.authors" :key="index">
                    <b-tag
                      rounded
                      type="is-info"
                      class="mr-2 mt-2"
                      closable
                      aria-close-label="Close tag"
                      @close="
                        formData.authors = formData.authors.filter(
                          e => e !== item
                        )
                      "
                      >{{ item }}</b-tag
                    >
                  </span>
                </b-field>
              </div>
              <div v-if="showFormData.publisher" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.publisher ? 'Publisher*' : 'Publisher'
                  "
                >
                  <b-input
                    v-model="formData.publisher"
                    :required="requiredFormData.publisher"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.placeOfPublication" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.placeOfPublication
                      ? 'Place of Publication*'
                      : 'Place of Publication'
                  "
                >
                  <b-input
                    v-model="formData.placeOfPublication"
                    :required="requiredFormData.placeOfPublication"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.language" class="column is-6">
                <b-field
                  :label="requiredFormData.language ? 'Language*' : 'Language'"
                >
                  <b-input
                    v-model="formData.language"
                    :required="requiredFormData.language"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.weblink" class="column is-6">
                <b-field
                  :label="contentType === 'video' ? 'Youtube URL' : 'URL Link'"
                >
                  <b-input
                    v-model="formData.weblink"
                    :required="requiredFormData.weblink"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.contributorNames" class="column is-6">
                <b-field
                  :label="
                    requiredFormData.contributorNames
                      ? 'Contributor Names*'
                      : 'Contributor Names'
                  "
                  message="Enter multiple values separated by comma (,)"
                >
                  <b-input
                    v-model="formData.contributorNames"
                    :required="requiredFormData.contributorNames"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.medium" class="column is-6">
                <b-field
                  :label="requiredFormData.medium ? 'Medium*' : 'Medium'"
                >
                  <b-input
                    v-model="formData.medium"
                    :required="requiredFormData.medium"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.dataType" class="column is-6">
                <b-field label="Data Type">
                  <b-input v-model="formData.dataType" expanded></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.dataSource" class="column is-6">
                <b-field label="Data Source">
                  <b-input v-model="formData.dataSource" expanded></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.category" class="column is-6">
                <b-field label="Category">
                  <b-input v-model="formData.category" expanded></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.license" class="column is-6">
                <b-field label="License">
                  <b-input v-model="formData.license" expanded></b-input>
                </b-field>
              </div>
              <div v-if="showFormData.format" class="column is-6">
                <b-field label="Format">
                  <b-input v-model="formData.format" expanded></b-input>
                </b-field>
              </div>
              <div v-if="false && showFormData.XXXXXXXX" class="column is-6">
                <b-field label="XXXXXXXX">
                  <b-input v-model="formData.XXXXXXXX" expanded></b-input>
                </b-field>
              </div>
            </div>
            <hr />
            <div class="has-text-right">
              <b-button @click="cancel" class="button">Cancel</b-button>
              <button
                form="editorForm"
                class="button is-primary ml-4"
                type="submit"
                :disabled="isSubmitting"
              >
                {{ isSubmitting ? "Saving" : "Save" }}
              </button>
            </div>
          </div>
        </div>
        <div class="column is-narrow">
          <div
            v-if="contentType === 'eBook' || contentType === 'PrintedBook'"
            class="box"
          >
            <b-field label="Cover Photo">
              <section>
                <b-field>
                  <b-upload
                    v-model="coverPhotoFile"
                    multiple
                    drag-drop
                    :required="requiredFormData.coverPhotoFile"
                  >
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"> </b-icon>
                        </p>
                        <p>Drop your files here or click to upload</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>

                <div class="tags">
                  <span
                    v-for="(file, index) in coverPhotoFile"
                    :key="index"
                    class="tag is-primary"
                  >
                    {{ file.name }}
                    <button
                      class="delete is-small"
                      type="button"
                      @click="deleteDropFile(index)"
                    ></button>
                  </span>
                </div>
              </section>
            </b-field>
          </div>
          <div
            v-if="
              contentType != 'PrintedBook' &&
                contentType != 'Newspaper' &&
                contentType != 'Research' &&
                contentType != 'Journal'
            "
            class="box"
          >
            <b-field label="Attachment">
              <section>
                <p
                  v-if="contentType == 'video'"
                  class="bold pb-4 has-text-info"
                >
                  <i class="mdi mdi-information-outline pr-1"></i> Note: If
                  video file is attached then YoutubeURL <br />
                  is discarded.
                </p>
                <p
                  v-if="contentType == 'video'"
                  class="bold pb-4 has-text-info"
                >
                  <i class="mdi mdi-information-outline pr-1"></i> Note: Only
                  mp4 format supports online playback
                </p>
                <b-field>
                  <b-upload
                    v-model="attachmentFile"
                    multiple
                    drag-drop
                    :required="requiredFormData.attachmentFile"
                  >
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"> </b-icon>
                        </p>
                        <p>Drop your file here or click to upload</p>
                      </div>
                    </section>
                  </b-upload>
                </b-field>

                <div class="tags">
                  <span
                    v-for="(file, index) in attachmentFile"
                    :key="index"
                    class="tag is-primary"
                  >
                    {{ file.name }}
                    <button
                      class="delete is-small"
                      type="button"
                      @click="deleteattachmentFile(index)"
                    ></button>
                  </span>
                </div>
              </section>
            </b-field>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import axios from "axios";
import Notification from "@/services/notificationService";
import api from "@/services/dataService";

export default {
  name: "Editor",
  data() {
    return {
      isSubmitting: false,
      contentType: "eBook",
      autoCompleteQuery: {
        keywords: "",
        authors: ""
      },
      formData: {
        kidsContent: false,
        premiumContent: false,
        ISBN: undefined,
        ISSN: undefined,
        journalName: undefined,
        newspaperSource: undefined,
        publicationDate: undefined,
        duration: undefined,
        accessionNo: undefined,
        callNo: undefined,
        subject: undefined,
        keywords: [],
        volume: undefined,
        pages: undefined,
        title: undefined,
        subTitle: undefined,
        description: undefined,
        notes: undefined,
        bookReview: undefined,
        authors: [],
        publisher: undefined,
        publicationYear: undefined,
        placeOfPublication: undefined,
        language: undefined,
        coverPhoto: undefined,
        weblink: undefined,
        contributorNames: undefined,
        medium: undefined,
        dataType: undefined,
        dataSource: undefined,
        category: undefined,
        license: undefined,
        format: undefined
      },
      showFormData: {
        kidsContent: true,
        premiumContent: true,
        ISBN: true,
        ISSN: true,
        journalName: true,
        newspaperSource: true,
        publicationDate: true,
        duration: true,
        accessionNo: true,
        callNo: true,
        subject: true,
        keywords: true,
        volume: true,
        pages: true,
        title: true,
        subTitle: true,
        description: true,
        notes: true,
        bookReview: true,
        authors: true,
        publisher: true,
        publicationYear: true,
        placeOfPublication: true,
        language: true,
        coverPhoto: true,
        weblink: true,
        contributorNames: true,
        medium: true,
        dataType: true,
        dataSource: true,
        category: true,
        license: true,
        format: true
      },
      requiredFormData: {
        kidsContent: false,
        premiumContent: false,
        ISBN: true,
        ISSN: false,
        journalName: false,
        newspaperSource: false,
        publicationDate: false,
        duration: false,
        accessionNo: false,
        callNo: false,
        subject: true,
        keywords: true,
        volume: false,
        pages: false,
        title: true,
        subTitle: false,
        description: true,
        notes: false,
        bookReview: false,
        authors: true,
        publisher: false,
        publicationYear: true,
        placeOfPublication: false,
        language: false,
        coverPhoto: false,
        weblink: false,
        contributorNames: false,
        medium: false,
        dataType: false,
        dataSource: false,
        category: false,
        license: false,
        format: false,
        coverPhotoFile: false,
        atttachmentFile: false
      },
      coverPhotoFile: [],
      attachmentFile: []
    };
  },
  async created() {
    this.showForm(this.contentType);
  },
  computed: {
    resourceURL() {
      return this.$store.state.resourceUrl;
    },
    autoComplete() {
      return this.$store.state.autoComplete;
    },
    filteredAuthorsSuggestions() {
      if (!this.autoComplete) return [];
      if (this.autoCompleteQuery.authors.length < 2) return [];

      return this.autoComplete.authors.filter(option => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.autoCompleteQuery.authors.toLowerCase()) >= 0
        );
      });
    },
    filteredKeywordsSuggestions() {
      if (!this.autoComplete) return [];
      if (this.autoCompleteQuery.keywords?.length < 2) return [];

      return this.autoComplete.keywords.filter(option => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.autoCompleteQuery.keywords.toLowerCase()) >= 0
        );
      });
    }
  },
  watch: {
    contentType: function(type) {
      this.showForm(type);
    }
  },
  methods: {
    checkAcessionNo() {
      api
        .get(
          "/Books/printed-book/exists?type=accn&number=" +
            this.formData.accessionNo
        )
        .then(() => {
          Notification.error("Accession no. already in use");
        })
        .catch(() => {
          Notification.success("Accession no. available");
        });
    },
    checkISBN() {
      api
        .get("/Books/e-book/exists?isbn=" + this.formData.ISBN)
        .then(() => {
          Notification.error("ISBN already in use");
        })
        .catch(() => {
          Notification.success("ISBN available");
        });
    },
    async addKeyword(keyword = "") {
      if (keyword != null && keyword.length > 0) {
        this.autoCompleteQuery.keywords = keyword;
      }
      if (
        this.autoCompleteQuery.keywords &&
        this.autoCompleteQuery.keywords.length > 0
      ) {
        const temp = this.autoCompleteQuery.keywords;
        await this.formData.keywords.push(temp);
        this.autoCompleteQuery.keywords = "";
      }
    },
    async addAuthor(author = "") {
      if (author != null && author.length > 0) {
        this.autoCompleteQuery.authors = author;
      }
      if (
        this.autoCompleteQuery.authors &&
        this.autoCompleteQuery.authors.length > 0
      ) {
        const temp = this.autoCompleteQuery.authors;
        await this.formData.authors.push(temp);
        this.autoCompleteQuery.authors = "";
      }
    },
    clearFormData() {
      this.autoCompleteQuery.keywords = "";
      this.autoCompleteQuery.authors = "";

      this.formData = {
        kidsContent: false,
        premiumContent: false,
        ISBN: undefined,
        ISSN: undefined,
        journalName: undefined,
        newspaperSource: undefined,
        publicationDate: undefined,
        duration: undefined,
        accessionNo: undefined,
        callNo: undefined,
        subject: undefined,
        keywords: [],
        volume: undefined,
        pages: undefined,
        title: undefined,
        subTitle: undefined,
        description: undefined,
        notes: undefined,
        bookReview: undefined,
        authors: [],
        publisher: undefined,
        publicationYear: undefined,
        placeOfPublication: undefined,
        language: undefined,
        coverPhoto: undefined,
        weblink: undefined,
        contributorNames: undefined,
        medium: undefined
      };
    },
    clearRequiredFormData() {
      this.requiredFormData = {
        kidsContent: false,
        premiumContent: false,
        ISBN: false,
        ISSN: false,
        journalName: false,
        newspaperSource: false,
        publicationDate: false,
        duration: false,
        accessionNo: false,
        callNo: false,
        subject: false,
        keywords: false,
        volume: false,
        pages: false,
        title: false,
        subTitle: false,
        description: false,
        notes: false,
        bookReview: false,
        authors: false,
        publisher: false,
        publicationYear: false,
        placeOfPublication: false,
        language: false,
        coverPhoto: false,
        weblink: false,
        contributorNames: false,
        medium: false,
        coverPhotoFile: false,
        atttachmentFile: false
      };
    },
    getDetailFromISBN() {
      const ISBNQuery = this.formData.ISBN;
      this.clearFormData();
      this.formData.ISBN = ISBNQuery;
      axios
        .get(
          "https://openlibrary.org/api/books?bibkeys=ISBN:" +
            this.formData.ISBN +
            "&jscmd=data&format=json"
        )
        .then(res => {
          var firstKey = Object.keys(res.data)[0];
          if (firstKey) {
            // getting description
            axios
              .get(
                "https://openlibrary.org/works/" + this.formData.ISBN + ".json"
              )
              .then(res => {
                this.formData.description = res.data.description;
              });
            // setting all the fetched data
            {
              Notification.success("Details fetched");
              this.formData.title = res.data[firstKey].title;
              this.formData.authors = res.data[firstKey].authors.map(
                a => a.name
              );

              this.formData.publicationYear = parseInt(
                res.data[firstKey].publish_date.slice(
                  res.data[firstKey].publish_date.length - 4
                )
              );
              this.formData.publisher = res.data[firstKey].publishers
                .map(a => a.name)
                .join(", ");
              this.formData.placeOfPublication = res.data[
                firstKey
              ].publish_places
                .map(a => a.name)
                .join(", ");
              this.formData.keywords = res.data[firstKey].subjects.map(
                a => a.name
              );
              this.formData.pages = res.data[firstKey].number_of_pages;
            }
            // fetching cover image
            const urlToObject = async () => {
              const response = await fetch(res.data[firstKey].cover.medium);
              // here image is url/location of image
              const blob = await response.blob();
              const coverImageFile = new File([blob], "image.jpg", {
                type: blob.type
              });
              this.coverPhotoFile.push(coverImageFile);
            };
            urlToObject();
          } else {
            Notification.error("No details found");
          }
        })
        .catch(err => {
          return err;
        });
    },
    hideAllFields() {
      Object.keys(this.showFormData).forEach(
        v => (this.showFormData[v] = false)
      );
    },
    showForm(type) {
      this.hideAllFields();
      this.clearRequiredFormData();
      let fields = [];
      let requiredFields = [];
      if (type == "PrintedBook") {
        fields = [
          "accessionNo",
          "ISBN",
          "callNo",
          "title",
          "subTitle",
          "authors",
          "subject",
          "keywords",
          "description",
          "bookReview",
          "volume",
          "pages",
          "language",
          "publicationYear",
          "publisher",
          "placeOfPublication"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear",
          "accessionNo",
          "ISBN",
          "callNo",
          "publisher",
          "placeOfPublication"
        ];
      } else if (type == "eBook") {
        fields = [
          "ISBN",
          "title",
          "subTitle",
          "authors",
          "subject",
          "keywords",
          "description",
          "bookReview",
          "volume",
          "pages",
          "language",
          "publicationYear",
          "publisher",
          "placeOfPublication"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear",
          "ISBN",
          "publisher",
          "placeOfPublication",
          "attachmentFile"
        ];
      } else if (type == "Journal") {
        fields = [
          "title",
          "ISSN",
          "journalName",
          "authors",
          "description",
          "publisher",
          "subject",
          "keywords",
          "language",
          "publicationYear",
          "volume"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear",
          "journalName"
        ];
      } else if (type == "eJournal") {
        fields = [
          "title",
          "ISSN",
          "journalName",
          "authors",
          "description",
          "publisher",
          "subject",
          "keywords",
          "language",
          "publicationYear",
          "volume"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear",
          "journalName"
        ];
      } else if (type == "Newspaper") {
        fields = [
          "title",
          "authors",
          "description",
          "newspaperSource",
          "publicationDate",
          "subject",
          "keywords",
          "language",
          "weblink",
          "publicationYear"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear",
          "publicationDate",
          "newspaperSource"
        ];
      } else if (type == "eNewspaper") {
        fields = [
          "title",
          "authors",
          "description",
          "newspaperSource",
          "publicationDate",
          "subject",
          "keywords",
          "language",
          "weblink",
          "publicationYear"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear",
          "publicationDate",
          "newspaperSource"
        ];
      } else if (type == "Research") {
        fields = [
          "title",
          "authors",
          "description",
          "publisher",
          "placeOfPublication",
          "publicationYear",
          "subject",
          "keywords",
          "weblink"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear",
          "placeOfPublication",
          "publisher"
        ];
      } else if (type == "eResearch") {
        fields = [
          "title",
          "authors",
          "description",
          "publisher",
          "placeOfPublication",
          "publicationYear",
          "subject",
          "keywords",
          "weblink"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear",
          "placeOfPublication",
          "publisher"
        ];
      } else if (type == "image") {
        fields = [
          "title",
          "description",
          "authors",
          "subject",
          "keywords",
          "notes",
          "publicationYear",
          "medium",
          "callNo"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear",
          "medium"
        ];
      } else if (type == "video") {
        fields = [
          "title",
          "description",
          "authors",
          "subject",
          "keywords",
          "weblink",
          "notes",
          "publicationYear",
          "duration"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear"
        ];
      } else if (type == "audio") {
        fields = [
          "title",
          "description",
          "authors",
          "subject",
          "keywords",
          "notes",
          "publicationYear",
          "duration"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear"
        ];
      } else if (type == "map") {
        fields = [
          "title",
          "description",
          "authors",
          "subject",
          "keywords",
          "publisher",
          "placeOfPublication",
          "notes",
          "publicationYear"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear",
          "publisher",
          "placeOfPublication"
        ];
      } else if (type == "dataset") {
        fields = [
          "title",
          "description",
          "authors",
          "subject",
          "keywords",
          "publisher",
          "dataType",
          "dataSource",
          "category",
          "license",
          "format",
          "placeOfPublication",
          "notes",
          "publicationYear"
        ];
        requiredFields = [
          "title",
          "subject",
          "description",
          "keywords",
          "authors",
          "publicationYear"
        ];
      }
      fields.forEach(field => {
        this.showFormData[field] = true;
      });
      requiredFields.forEach(field => {
        this.requiredFormData[field] = true;
      });
    },
    deleteDropFile(index) {
      this.coverPhotoFile.splice(index, 1);
    },
    deleteattachmentFile(index) {
      this.attachmentFile.splice(index, 1);
    },
    cancel() {
      this.clearFormData();
    },
    async submitForm() {
      this.isSubmitting = true;

      // generating request from form data
      let request = cloneDeep(this.formData);

      // preparing arrays
      request["keywords"] = request["keywords"].map(function(item) {
        return item.trim();
      });
      request["authors"] = request["authors"].map(function(item) {
        return item.trim();
      });

      // prepare dispatch request (string identifier for vuex store)
      let dispatchRequest = "";
      if (this.contentType[0] == "e") {
        dispatchRequest = "addE" + this.contentType.slice(1);
      } else if (this.contentType == "PrintedBook") {
        dispatchRequest = "addBook";
      } else if (
        this.contentType == "image" ||
        this.contentType == "video" ||
        this.contentType == "audio" ||
        this.contentType == "map" ||
        this.contentType == "dataset"
      ) {
        dispatchRequest = "addDigitalMedia";
        const formDataRequest = new FormData();
        formDataRequest.append("type", this.contentType);
        formDataRequest.append("attachmentURL", request.weblink);
        formDataRequest.append("attachmentFile", this.attachmentFile[0]);

        // handling array for formData
        for (let i = 0; i < request["keywords"].length; i++) {
          formDataRequest.append("keywords", request["keywords"][i]);
        }
        for (let i = 0; i < request["authors"].length; i++) {
          formDataRequest.append("authors", request["authors"][i]);
        }
        delete request["keywords"];
        delete request["authors"];

        // copying rest of the request in formdata
        for (var key2 in this.formData) {
          if (key2 != "attachmentFile" && request[key2] != undefined)
            formDataRequest.append(key2, request[key2]);
        }

        request = formDataRequest;
      } else {
        dispatchRequest = "add" + this.contentType;
      }

      // Sending request to server
      const resourceObject = await this.$store
        .dispatch(dispatchRequest, request)
        .catch(err => {
          if (err) {
            this.isSubmitting = false;
          }
        });

      // Handling uploads if add request is successful
      if (resourceObject._id) {
        if (
          this.contentType == "image" ||
          this.contentType == "video" ||
          this.contentType == "audio" ||
          this.contentType == "map" ||
          this.contentType == "dataset"
        ) {
          // if digital media skip individual uploads on success
          this.clearFormData();
        } else {
          const uploadRequest = new FormData();
          uploadRequest.append("coverPhoto", this.coverPhotoFile[0]);
          uploadRequest.append("attachment", this.attachmentFile[0]);
          uploadRequest.append("attachmentTitle", resourceObject.title);
          uploadRequest.append("resourceId", resourceObject._id);
          uploadRequest.append("resourceType", this.contentType);

          if (this.coverPhotoFile[0]) {
            await this.$store.dispatch("coverUpload", uploadRequest);
          }
          if (this.attachmentFile[0]) {
            await this.$store.dispatch("attachmentUpload", uploadRequest);
          }
          this.clearFormData();
        }
      }
      this.isSubmitting = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
